import en from './en';
import ar from './ar';

export default {
  En: en,
  Ar: ar,
  en,
  ar,
  EN: en,
  AR: ar,
};
