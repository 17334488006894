import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import c from '../../utils/constants';
import sanitize from '../../utils/sanitizer';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalizedStrings from 'react-localization';
import stringsToLocalize from '../../localisation';

import Error from '../Error';

const ErrorHandler = (props) => {
  const strings = new LocalizedStrings(stringsToLocalize);
  strings.setLanguage(props.lang);
  const [msg, setMsg] = useState(null);
  const [msgDesc, setMsgDesc] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errModal, setErrModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    props.setErrorHandler(null);
  };
  useEffect(() => {
    function handleError() {
      const [cont, err, message, messageDesc] = returnMsg(props.msg);
      setMsg(message);
      setMsgDesc(messageDesc);
      if (!cont) {
        setErrModal(true);
        setError(true);
        if (err) {
          setError(true);
          props.setStop(true);
          setShowModal(false);
        } else {
          setError(false);
          props.setStop(false);
          setShowModal(true);
        }
      } else {
        setErrModal(false);
        setError(false);
        props.setStop(false);
        setShowModal(true);
      }
    }
    handleError();
    return () => {
      setMsg(null);
      setMsgDesc(null);
      props.setErrorHandler(null);
    };
  }, []);

  const returnMsg = (message) => {
    switch (message) {
      case 'NVN':
        return [false, 0, strings.nvn, strings.nvnDesc];
      case 'CD':
        return [true, 0, strings.cd, strings.cdDesc];
      case 'WD':
        return [false, 0, strings.wd, strings.wdDesc];
      case 'TA':
        return [false, 1, strings.ta, strings.taDesc];
      case 'ID':
        return [false, 0, strings.id, strings.idDesc];
      case 'CV':
        return [false, 0, strings.cv, strings.cvDesc];
      case 'WP':
        return [false, 0, strings.wp, strings.wpDesc];
      case 'OR':
        return [true, 0, strings.or, strings.orDesc];
      case 'IP':
        return [false, 0, strings.ip, strings.ipDesc];
      case 'WO':
        return [false, 0, strings.wo, strings.woDesc];
      default:
        return [false, 1, strings.error, strings.errDesc];
    }
  };
  const modal = (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      centered
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title>
          <h4 style={{fontWeight: '100'}}>
            {' '}
            <FontAwesomeIcon
              color={errModal ? c.orange : c.primary}
              icon={errModal ? faExclamationTriangle : faInfoCircle}
            ></FontAwesomeIcon>{' '}
              {msg}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p
          dir={strings.dir}
          style={
            strings.dir.toLowerCase() === 'rtl'
              ? { textAlign: 'right',  fontSize: '2em' }
              : { textAlign: 'left',  fontSize: '2em' }
          }
          dangerouslySetInnerHTML={{ __html: sanitize(msgDesc) }}
        ></p>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <Button variant='secondary' onClick={handleCloseModal}>
          {strings.ok}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {!error && modal}
      {error && <Error ErrorMsg={msg} ErrMsgDesc={msgDesc} />}
    </>
  );
};
export default ErrorHandler;
