import prodconfig from './prod.json';
import devconfig from './dev.json';
let config = null;
switch (process.env.REACT_APP_ENV.toLowerCase()) {
  case 'production':
    config = prodconfig;
    break;
  case 'local':
    config = devconfig;
    break;
  case 'development':
    config = prodconfig;
    break;
  default:
    config = devconfig;
    break;
}

export default config;
